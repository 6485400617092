import gql from 'graphql-tag'


export const LIST_ORDER = (templateType) => gql`query LIST_ORDER ($docType: String!, $docId: Int!) {
  orders: listDoc${templateType}Order (docType: $docType, docId: $docId) {
    id type name docId parentId
    unitId unit {id name}
    inventoryId inventory {id type code name}
    qty price totalPrice
  }
}`

export const CREATE_ORDER = (templateType) => gql`mutation CREATE_ORDER ($docType: String!, $type: String!, $docId: Int!, $input: Doc${templateType}OrderInput!) {
  createOrder: createDoc${templateType}Order (docType: $docType, type: $type, docId: $docId, input: $input) {id}
}`

export const DESTROY_ORDER = (templateType) => gql`mutation DESTROY_ORDER ($docType: String!, $orderId: Int!) {
  destroyOrder: destroyDoc${templateType}Order (docType: $docType, orderId: $orderId) {id}
}`

export const WATCH_ORDER_UPDATED = (templateType) => gql`subscription WATCH_ORDER_UPDATED ($docType: String!, $docId: Int!) {
  orderUpdated: watchDoc${templateType}OrderUpdated (docType: $docType, docId: $docId) {
    id type name docId parentId
    unitId unit {id name}
    inventoryId inventory {id type code name}
    qty price totalPrice
  }
}`

export const WATCH_ORDER_DESTROYED = (templateType) => gql`subscription WATCH_ORDER_DESTROYED ($docType: String!, $docId: Int!) {
  orderDestroyed: watchDoc${templateType}OrderDestroyed (docType: $docType, docId: $docId) {id parentId}
}`


export const LIST_ORDER_INVENTORY = (templateType) => gql`query LIST_ORDER_INVENTORY ($docType: String!, $type: String!, $contactId: Int!, $q: FilterInput, $qty: Float, $docConfigId: Int) {
  inventories: listDoc${templateType}OrderInventory (docType: $docType, type: $type, contactId: $contactId, q: $q, qty: $qty, docConfigId: $docConfigId) {
    id type name isDefault salesPrice
    inventoryId inventory {id type code name}
    unitId unit {id name}
    children {
      id type name converter
    }
  }
}`

export const LIST_ORDER_EXPENSE = (templateType) => gql`query LIST_ORDER_EXPENSE ($docType: String!, $contactId: Int!) {
  expenses: listDoc${templateType}OrderExpense (docType: $docType, contactId: $contactId) {
    id type name isDefault salesPrice
    unitId unit {id name}
  }
}`

export const LIST_ORDER_DISCOUNT_PRICE = (templateType) => gql`query LIST_ORDER_DISCOUNT_PRICE ($docType: String!, $contactId: Int!) {
  discountPrices: listDoc${templateType}OrderDiscountPrice (docType: $docType, contactId: $contactId) {
    id type name isDefault discountPrice
    unitId unit {id name}
  }
}`

export const LIST_ORDER_VAT_PRICE = (templateType) => gql`query LIST_ORDER_VAT_PRICE ($docType: String!) {
  vatPrices: listDoc${templateType}OrderVatPrice (docType: $docType) {
    id type name isDefault vatPrice
    unitId unit {id name}
  }
}`

export const LIST_ORDER_NET_PRICE = (templateType) => gql`query LIST_ORDER_NET_PRICE ($docType: String!) {
  netPrices: listDoc${templateType}OrderNetPrice (docType: $docType) {
    id type name isDefault
    unitId unit {id name}
  }
}`
