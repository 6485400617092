import gql from 'graphql-tag'


export const LIST_CONTACT = (templateType) => gql`query LIST_CONTACT ($docType: String!, $q: FilterInput) {
  contacts: listDoc${templateType}Contact (docType: $docType, q: $q) {
    id type code name isDefault branch isUnknown
  }
}`

export const LIST_SALES_CREDIT = (templateType) => gql`query LIST_SALES_CREDIT ($docType: String!, $contactId: Int!, $docId: Int) {
  docConfigs: listDoc${templateType}SalesCredit (docType: $docType, contactId: $contactId, docId: $docId) {
    id salesCredit isDefault
  }
}`

export const LIST_LOGISTIC = (templateType) => gql`query LIST_LOGISTIC ($docType: String!, $contactId: Int!, $docId: Int) {
  contacts: listDoc${templateType}Logistic (docType: $docType, contactId: $contactId, docId: $docId) {
    id type code name isDefault
  }
}`

export const LIST_SALES = (templateType) => gql`query LIST_SALES ($docType: String!, $contactId: Int, $docId: Int) {
  contacts: listDoc${templateType}Sales (docType: $docType, contactId: $contactId, docId: $docId) {
    id type code name isDefault
  }
}`

export const LIST_SALES_LOCATION = (templateType) => gql`query LIST_SALES_LOCATION ($docType: String!, $contactId: Int!) {
  contacts: listDoc${templateType}SalesLocation (docType: $docType, contactId: $contactId) {
    id type code name isDefault
  }
}`
